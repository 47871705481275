import cx from 'classnames'

import { type SanityImageAndTextModule } from '@data/sanity/queries/types/modules'
import { backgroundColorClasses } from '@lib/color'

import ComplexPortableText from '@components/complex-portable-text'
import Photo from '@components/photo'

type ImageAndTextProps = Pick<
  SanityImageAndTextModule,
  'image' | 'textContent' | 'textPosition' | 'background'
>

const ImageAndText = ({
  image,
  textContent,
  textPosition,
  background,
}: ImageAndTextProps) => (
  <section
    className={cx(
      'grid md:grid-cols-2 items-center',
      background ? backgroundColorClasses[background] : '',
    )}
  >
    {!!textContent && (
      <ComplexPortableText
        content={textContent}
        className={cx(
          `rc order-2 w-full max-w-[calc(var(--container-xl)/2)] px-5 py-10 md:py-20`,
          {
            'md:pl-[10%]': textPosition === 'right',
            'md:pr-[10%] md:ml-auto md:order-1': textPosition === 'left',
          },
        )}
      />
    )}

    {!!image && (
      <Photo
        image={image}
        fill
        className={cx('relative h-full order-1', {
          'md:order-2': textPosition === 'left',
        })}
        imageClassName="object-cover"
        style={{
          paddingTop: `${
            100 /
            (!!image.customRatio
              ? image.customRatio
              : image.asset.metadata.dimensions.aspectRatio)
          }%`,
        }}
      />
    )}
  </section>
)

export default ImageAndText
